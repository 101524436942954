.navbar-admin--logo--menu {
    min-width: 200px;
    /* width: 244px; */
    max-width: 300px;
    background-color: var(--primary-background-color);
    min-height: 100vh;
    flex-shrink: 0;
    padding: 10px 20px;
    /* Prevents the logo menu from shrinking */
}

.navbar-admin--container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.navbar-admin--content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.admin--content--row {
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 20px;
}

.profileadmin-image {
    padding: 9px;
}

.profileadmin-image img {
    max-height: 32px;
    min-height: 32px;
    max-width: 32px;
    min-width: 32px;
    border-radius: 50%;
    border: none;
}

.navbar--admin--tile {
    font-size: 1.5rem;
    font-weight: 600;
}

.admin-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 30px;
}

.admin-menu>li {
    padding: 10px;
    /* border: 1px solid var(--primary-red); */
    margin: 5px 0px;
    border-radius: 10px;
    color: var(--menu-text);
}

.admin-menu>li:hover {
    color: var(--menu-text-hover);
}

.admin-menu>li>div {
    display: flex;
    align-items: center;
    gap: 5px;
    /* justify-content: space-between; */
    cursor: pointer;
}

.admin-menu>li>div>a {
    color: var(--menu-text);
    /* color: #000; */
    text-decoration: none;
    display: block;

}

.admin-menu>li>div>a:hover {
    color: var(--menu-text-hover);

}

.admin-menu>li>ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 10px;
}

.admin-menu>li>ul>li {
    padding: 5px 0;
    padding: 10px;
    color: var(--menu-text);
    margin: 5px 0px;
    border-radius: 10px;
}

.admin-menu>li>ul>li>a {
    /* color: #000; */
    text-decoration: none;
    color: var(--menu-text);

}

.dropdown-arrow {
    margin-left: 10px;
    font-size: 0.8rem;
}

.admin-menu li.active {
    color: var(--menu-text-hover);

    background-color: #f0f0f0;
    /* Adjust this as needed */
    /* font-weight: bold; */
    /* Optional: to highlight the active tab */
}

.admin-menu>li.active div>a {
    color: var(--menu-text-hover);

}

.icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.fullwidthcontainer {
    padding: 20px;
}

.admin--content--row {
    border-bottom: 1px solid #c0c0c0;
}