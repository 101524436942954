.productContainer--ap {
    display: grid;
    grid-template-columns: 63% 35%;
    justify-content: space-between;
}

.left-ap,
.right-ap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.generalInformation--ap--container,
.productMedia--ap-container,
.productInventory--ap-container,
.productCategory-details-ap,
.metainformation--ap-container {
    width: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    padding: 20px 20px;
}

.heading--container {
    font-size: 1.2rem;
}

.inputsforGeneral--ap {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.inputsforGeneral--ap label p {
    color: rgb(94, 94, 94);
    font-weight: 400;
    font-size: .95rem;
}



.inputsforGeneral--ap label input,
.inputsforGeneral--ap label textarea,
.inputsforGeneral--ap label select {
    width: 100%;
    border: 1px solid #c0c0c0;
    padding: 10px;
    background-color: var(--primary-background-color);
    border-radius: 10px;
    color: #000;
    max-width: 100%;
}


.imgPreview--ap {
    max-width: 100px;
    max-height: 100px;
}

.ap--l--gd {
    width: 100%;
    display: grid;
    justify-content: space-between;
}

.tag-item {
    padding: 5px;
    border-radius: 5px;
    border: none;
    background-color: var(--primary-background-color);
}

.tags-display-container,
.tag-item {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 8px;
    font-size: .8rem;
    align-items: center;

    button {
        background-color: transparent;
        border: none;
    }
}

.tags-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    input {
        width: 75% !important;
    }

    button {
        border-radius: 10px;
        padding: 8px 10px;
        border: none;
        background-color: green;
        color: #fff;
    }

}

.btnSubmit--ap {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 20px;

    button {
        padding: 10px 20px;
        font-size: .95rem;
        border-radius: 10px;

    }
}

.discardChanges {
    border: 1px solid red;
    background-color: #fff;
    color: red;
}

.confirmPost {
    border: 1px solid green;
    background-color: green;
    color: #fff;
}

.inputFileContainer-designed {
    width: 100%;
    height: 100px;
    border-radius: 20px;
    border: 1px dashed #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 20px; */
}

.fileVisibility {
    visibility: hidden;
}

.formcontainer--ac {
    display: flex;
    padding-right: 40px;
    flex-direction: column;
    gap: 20px;
}

.formcontainer--ac label {
    grid-template-columns: 30% 70%;
    display: grid;
    row-gap: 10px;
    width: 100%;
    gap: 10px;
}

.formcontainer--ac label p {
    font-weight: 500;
}

.formcontainer--ac label input {
    height: 40px;
    border: 1px solid #c0c0c0;
    padding: 10px;
    border-radius: 10px;
    font-size: .95rem;
}

.formcontainer--ac label textarea {
    border: 1px solid #c0c0c0;
    height: 200px;
    border-radius: 10px;
    padding: 10px;
    font-size: .95rem;

}

.formcontainer--ac label input::placeholder,
.formcontainer--ac label textarea::placeholder {
    font-size: .95rem;
}

.labelInput--ac {
    align-items: center;
}

.submitBtn--ac {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.submitBtn--ac button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: green;
    color: #fff;
}

select,
option {
    padding: 10px;
}

.rd--ac {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.rd--ac label {
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.toppart-dashboard {
    display: grid;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.tp-d--child {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    padding: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 10px;

}

.tp-d--child:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.bm-tp-d--child {
    font-size: 2rem;
    padding-top: 10px;
}

.tp-tp-d--child {
    font-size: 1rem;
}

/* route.css */

/* route.css */

.gridDisplay--dashboard {
    display: grid;
    grid-template-columns: 63% 35%;
    gap: 1rem;
    /* Optional: Adjust gap between columns as needed */
    width: 100%;
    /* Ensure the grid respects the container width */
    box-sizing: border-box;
    margin-top: 24px;
    /* Include padding and border in the element's total width and height */
}

.gridDisplay--dashboard-2 {
    display: grid;
    grid-template-columns: 35% 63%;
    gap: 1rem;
    /* Optional: Adjust gap between columns as needed */
    width: 100%;
    /* Ensure the grid respects the container width */
    box-sizing: border-box;
    margin-top: 24px;
}

.left--gdd,
.right--gdd {
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #fff;
    /* Optional: For visual distinction */
    padding: 1rem;
    /* Optional: Adjust padding as needed */
    overflow: auto;
    /* Prevent content overflow */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.headingDG {
    margin-bottom: 10px;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position above the image */
    /* left: 20%; */
    bottom: 0px;
    margin-left: -100%;
    /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    font-size: 12px;
}