* {
    margin: 0;
    padding: 0;

    box-sizing: border-box;

}

@media print {

    .table,
    .table__body {
        overflow: visible;
        height: auto !important;
        width: auto !important;
    }
}

/*@page {
    size: landscape;
    margin: 0; 
}*/


#customers_table.table {
    width: 100%;
    height: 100%;
    background-color: #fff5;

    backdrop-filter: blur(7px);
    box-shadow: 0 .4rem .8rem #0005;
    border-radius: 20px;

    overflow: hidden;
}

.table__header {
    width: 100%;
    height: 10%;
    background-color: #fff4;
    padding: .8rem 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table__header .input-group {
    width: 35%;
    height: 100%;
    background-color: #fff5;
    padding: 0 .8rem;
    border-radius: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .2s;
}

.table__header .input-group:hover {
    width: 45%;
    background-color: #fff8;
    box-shadow: 0 .1rem .4rem #0002;
}

.table__header .input-group img {
    width: 1.2rem;
    height: 1.2rem;
}

.table__header .input-group input {
    width: 100%;
    padding: 0 .5rem 0 .3rem;
    background-color: transparent;
    border: none;
    outline: none;
}

.table__body {
    width: 100%;
    max-height: calc(89% - 1.6rem);
    background-color: var(--primary-background-color);

    margin: 0px;
    border-radius: .6rem;

    overflow: auto;
    overflow: overlay;
}


.table__body::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: #0004;
    visibility: hidden;
}

.table__body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}


table {
    width: 100%;
}

td img {
    width: 36px;
    height: 36px;
    margin-right: .5rem;
    border-radius: 50%;

    vertical-align: middle;
}

table,
th,
td {
    border-collapse: collapse;
    padding: 1rem;
    text-align: center;
}

thead th {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--primary-background-color);
    cursor: pointer;
    text-transform: capitalize;
    color: var(--menu-text-hover);
    font-weight: 500;
}

tbody tr:nth-child(even) {
    background-color: #0000000b;
}

tbody tr {
    --delay: .1s;
    transition: .5s ease-in-out var(--delay), background-color 0s;
}

tbody tr.hide {
    opacity: 0;
    transform: translateX(100%);
}

tbody tr:hover {
    background-color: #fff6 !important;
}

tbody tr td,
tbody tr td p,
tbody tr td img {
    transition: .2s ease-in-out;
}

tbody tr.hide td,
tbody tr.hide td p {
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: .2s ease-in-out .5s;
}

tbody tr.hide td img {
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}

.status {
    padding: .4rem 0;
    border-radius: 2rem;
    text-align: center;
}

.status.delivered {
    background-color: #86e49d;
    color: #006b21;
}

.status.cancelled {
    background-color: #d893a3;
    color: #b30021;
}

.status.pending {
    background-color: #ebc474;
}

.status.shipped {
    background-color: #6fcaea;
}


@media (max-width: 1000px) {
    td:not(:first-of-type) {
        min-width: 12.1rem;
    }
}

thead th span.icon-arrow {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 1.4px solid transparent;

    text-align: center;
    font-size: 1rem;

    margin-left: .5rem;
    transition: .2s ease-in-out;
}

thead th:hover span.icon-arrow {
    border: 1.4px solid #6c00bd;
}

thead th:hover {
    color: #6c00bd;
}

thead th.active span.icon-arrow {
    background-color: #6c00bd;
    color: #fff;
}

thead th.asc span.icon-arrow {
    transform: rotate(180deg);
}

thead th.active,
tbody td.active {
    color: #6c00bd;
}

.export__file {
    position: relative;
}

.export__file .export__file-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: #fff6 url(./../assets/export.png) center / 80% no-repeat;
    border-radius: 50%;
    transition: .2s ease-in-out;
}

.export__file .export__file-btn:hover {
    background-color: #fff;
    transform: scale(1.15);
    cursor: pointer;
}

.export__file input {
    display: none;
}

.export__file .export__file-options {
    position: absolute;
    right: 0;

    width: 12rem;
    border-radius: .5rem;
    overflow: hidden;
    text-align: center;

    opacity: 0;
    transform: scale(.8);
    transform-origin: top right;

    box-shadow: 0 .2rem .5rem #0004;

    transition: .2s;
}

.export__file input:checked+.export__file-options {
    opacity: 1;
    transform: scale(1);
    z-index: 100;
}

.export__file .export__file-options label {
    display: block;
    width: 100%;
    padding: .6rem 0;
    background-color: #f2f2f2;

    display: flex;
    justify-content: space-around;
    align-items: center;

    transition: .2s ease-in-out;
}

.export__file .export__file-options label:first-of-type {
    padding: 1rem 0;
    background-color: #86e49d !important;
}

.export__file .export__file-options label:hover {
    transform: scale(1.05);
    background-color: #fff;
    cursor: pointer;
}

.export__file .export__file-options img {
    width: 2rem;
    height: auto;
}

.buttonPAginationCircle {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid var(--primary-red);
}

#BtnPagination {
    padding: 5px;
    color: var(--primary-red);
}

.paginationOrders {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: 24px;
}

.divContentDeleteorUpdate {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;

    button {
        padding: 5px;
        height: 25px;
        width: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: none;
        color: #fff;
    }
}

.editMd {
    background-color: #47b6de;
}

.deleteMd {
    background-color: red;
}

.export-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 10px;

    button {
        background-color: #006b21;
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
        border: none;
    }
}

.filters {
    display: grid;
    /* grid-template-columns: 50% 50%; */
    /* padding-right: 40px; */
    gap: 10px;
    margin-bottom: 20px;
}

.filters input[type=text] {
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
}

.filter-flexx {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}